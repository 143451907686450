import { QueryClient, QueryClientProvider } from 'react-query'
import { SessionProvider } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { AnimatePresence } from 'framer-motion'
import { ConfigProvider } from 'antd'
import Script from 'next/script'
import Router from 'next/router'
import moment from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/es'
import esES from 'antd/lib/locale/es_ES'
import type { AppProps } from 'next/app'
import MainLayout from 'layouts/MainLayout'
import HeadSeo from '@/components/HeadSeo'
import NProgress from 'nprogress'

import { Provider } from 'react-redux'
import store from '@/app/store'
import { GTMPageView, GA_MEASUREMENT_ID } from '@/services/gtm'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import es from 'dayjs/locale/es'

import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'

import 'nprogress/nprogress.css'
import 'react-multi-carousel/lib/styles.css'
import '@/styles/tailwind.css'
import 'antd/dist/antd.variable.min.css'
import '@/styles/fonts.css'
import '@/styles/globals.css'
import '@/styles/antd-theme.css'
import Head from 'next/head'

dayjs.extend(localeData)
dayjs.locale(es)
dayjs.Ls.es.weekStart = 1
dayjs.extend(customParseFormat)

export type NetworksType = {
	[name: string]: {
		url: string
		name?: string
	}
}

moment.locale('es')

NProgress.configure({
	showSpinner: false,
})

const globalSettings = {
	logoUrl: '/vaissen.svg',
	networks: {
		facebook: {
			url: 'https://www.facebook.com',
		},
		twitter: {
			url: 'https://twitter.com',
		},
		instagram: {
			url: 'https://www.instagram.com',
		},
	},
}

ConfigProvider.config({
	theme: {
		primaryColor: '#021C49',
	},
})

function GrfApp({ Component, pageProps, ...appProps }: AppProps & { logoUrl: string; networks: NetworksType }) {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false,
						refetchOnReconnect: false,
						retry: 1,
						staleTime: 5 * 60 * 1000,
					},
				},
			})
	)

	const { logoUrl, networks } = globalSettings

	// Initiate GTM
	useEffect(() => {
		console.log(dayjs.extend(customParseFormat))
	}, [])

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			GTMPageView(url)
			NProgress.done()
		}

		Router.events.on('routeChangeStart', () => {
			NProgress.start()
		})

		Router.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [])

	if (appProps?.router.route === '/404') {
		return <Component {...pageProps} />
	}

	return (
		<>
			{/* script google tag manager */}
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${GA_MEASUREMENT_ID}');`}
			</Script>

			<Head>
				<meta name="facebook-domain-verification" content="car5453sbree746a7cz7xb4z3prynq" />
			</Head>

			{/* Google Tag Manager (noscript) */}
			<noscript
				dangerouslySetInnerHTML={{
					__html: `<iframe
                            src="https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}"
                            height="0"
                            width="0"
                            style="display:none;visibility:hidden"
                        />`,
				}}
			/>
			{/* End Google Tag Manager (noscript) */}
			{/*<!-- Global site tag (gtag.js) - Google Analytics -->*/}
			{/* <Script
				src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
				strategy="afterInteractive"
			/>
			<Script id="google-analytics" strategy="afterInteractive">
				{`
				  window.dataLayer = window.dataLayer || [];
				  function gtag(){window.dataLayer.push(arguments);}
				  gtag('js', new Date());

				  gtag('config', '${GA_MEASUREMENT_ID}');
				`}
			</Script> */}

			<CookiesProvider>
				<AnimatePresence
					exitBeforeEnter
					initial={false}
					onExitComplete={() => {
						//- window.scrollTo(0, 0)
					}}
				>
					<Provider store={store}>
						<SessionProvider session={pageProps.session}>
							<QueryClientProvider client={queryClient}>
								<ConfigProvider locale={esES}>
									<MainLayout logoUrl={logoUrl} networks={networks} session={pageProps.session}>
										<Component {...pageProps} />
									</MainLayout>
								</ConfigProvider>
							</QueryClientProvider>
						</SessionProvider>
					</Provider>
				</AnimatePresence>
			</CookiesProvider>
		</>
	)
}

export default GrfApp
